/*!
 * Evil Corp (https://evilcorp.com.ar/)
 * Copyright 2004-2020 EvilCorp S.A.
 */

.accordion .card-header .btn-link{
	color: #666;
	display: block;
	width: 100%;
	text-align: left;
}

.accordion .card-header .btn-link:hover,
.accordion .card-header .btn-link:active,
.accordion .card-header .btn-link:focus{
	color: #222;
	text-decoration: none;
}


.filters-selector{
	form{
		-ms-flex-pack: center!important;
    	justify-content: center!important;
	}
	.form-group{
		@extend .pr-4;
	}
	label{
		@extend .pr-2;
	}
}

.metric{
	background-color: #F4F4F4;
	border-radius: 8px;
	padding: 12px;
}